import React from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
 
class Banner extends React.Component {
    state = {
        isOpen: false
    }
    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        let modalStyles = {overlay: {zIndex: 100000000000000}};
        return (
            <>
                <div id="home" className="uk-banner main-banner item-bg1">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="uk-container">
                                <div className="main-banner-content">
                                    <h1>グローバルな<br/>ウェブ開発</h1>
                                    <p>世界で通用するを武器にする時代へ</p>
                                    <a href="#contact" className="uk-button uk-button-default">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Banner;