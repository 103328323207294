import React from 'react';
import about1 from '../../assets/images/about1.jpg';
import about2 from '../../assets/images/about2.jpg';
import one from '../../assets/images/1.png';
 
class About extends React.Component {
    render(){
        return (
            <section id="about" className="uk-about  about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>About Us</span>
                                    <h2>Webサービスやウェブ開発</h2>
                                    <div className="bar"></div>
                                </div>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    <h3>海外での経験が豊富なウィルクラウドだからこそできる</h3>
                                    <p>海外へ向けたサイト開発や英訳から国内のウェブサイト開発まで</p>
                                    <p>最後まで全面サポート致します</p>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="about-img">
                                <img src={about1} className="about-img1" alt="about-img" />
                                <img src={about2} className="about-img2" alt="about-img" />
                                <img src={one} className="shape-img" alt="shape" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;