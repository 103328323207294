import './assets/css/flaticon.css';
import './assets/css/animate.min.css';
import 'uikit/dist/css/uikit.min.css';
import 'react-modal-video/css/modal-video.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Preloader from './components/Common/Preloader';
import HomeTwo from './components/Pages/HomeTwo';

class App extends React.Component {
    state = {
        loading: true
    };
    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }
    
    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    render() {
        return (
            <Router>
                <>
                    {this.state.loading ? <Preloader /> : ''}
                    <Route path="/" exact component={HomeTwo}/>
                </>
            </Router>
        );
    }
}

export default App;
