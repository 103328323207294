import React from 'react';
import NavigationTwo from '../Navigation/NavigationTwo';
import Banner from '../HomeTwo/Banner';
import FeaturesTwo from '../HomeTwo/FeaturesTwo';
import SeperatedBar from '../HomeTwo/SeperatedBar';
import About from '../HomeTwo/About';
import Services from '../HomeTwo/Services';
import Project from '../HomeTwo/Project';
import Testimonials from '../HomeTwo/Testimonials';
import PartnerLogo from '../HomeTwo/PartnerLogo';
import Team from '../HomeTwo/Team';
import Newsletter from '../HomeTwo/Newsletter';
import LatestNews from '../HomeTwo/LatestNews';
import Contact from '../HomeTwo/Contact';
import Footer from '../Common/Footer';
 
class Home extends React.Component {
    render(){
        return (
            <>
                <NavigationTwo />

                {/* Main Banner */}
                <Banner />

                {/* Features Area */}
                <FeaturesTwo />

                {/* Vertical line */}
                <SeperatedBar />

                {/* About Area */}
                <About />

                {/* Vertical line */}
                <SeperatedBar />

                {/* Vertical line */}
                <SeperatedBar />

                {/* Contact Area */}
                <Contact />
                
                {/* Footer Area */}
                <Footer />
            </>
        );
    }
}
 
export default Home;